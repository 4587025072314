
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref, computed } from "vue"
import { SelectModel } from "@/core/models/SelectModel"
import { ElForm } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { CaseReport } from "@/core/models/CaseReport"
import { ReportType } from "@/core/enums/enums"
import { today0000, today2359 } from "@/core/helpers/dateformat"

export default defineComponent({
  name: "finance-case",
  components: {},
  setup() {
    type FormInstance = InstanceType<typeof ElForm>
    const ruleFormRef = ref<FormInstance>()

    const appUrl = process.env.VUE_APP_URL ?? ""

    const caseReport = ref<CaseReport>({
      branchId: "",
      startDate: today0000(), //new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: today2359(), //new Date(new Date().setHours(23, 59, 59, 999)),
    } as CaseReport)

    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)

    const branchList = ref<SelectModel[]>([])
    const businessList = ref<SelectModel[]>([])

    const activeBusinessId = ref<string>()
    const activeBranchId = ref<string>()

    const rules = ref({
      activeBusinessId: getRule(RuleTypes.SELECT, "İşletme"),
      activeBranchId: getRule(RuleTypes.SELECT, "Şube"),
      startDate: getRule(RuleTypes.SELECT, "Başlangıç"),
      endDate: getRule(RuleTypes.SELECT, "Bitiş"),
    })

    const formData = computed(() => ({
      activeBusinessId: activeBusinessId.value,
      activeBranchId: activeBranchId.value,
      ...caseReport.value,
    }))

    //const disabledDate = computed(() => pickerOptions.disabledDate)

    const OnChangeBusiness = async businessId => {
      branchList.value = []
      await getBranchList(businessId)
    }

    const getBusinessList = async () => {
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length === 1) {
        activeBusinessId.value = businessList.value[0].id
        await OnChangeBusiness(activeBusinessId.value)
      }
    }

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      isBranchSelectLoading.value = false
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (branchList.value.length === 1) {
        activeBranchId.value = branchList.value[0].id
      }
    }

    const formValidate = async formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          getCaseReport()
        }
      })
    }

    const getCaseReport = async () => {
      /*
      const query = {
        params: {
          reportDate: posReport.value.reportDate,
          cashBookId: posReport.value.cashBookId,
        },
      }*/
      await ApiService.report({
        report: ReportType.FinanceReport,
        payload: JSON.stringify({
          branchId: activeBranchId.value,
          startDate: caseReport.value.startDate,
          endDate: caseReport.value.endDate,
        }),
      })
    }

    onMounted(async () => {
      await getBusinessList()
    })

    return {
      appUrl,
      rules,
      formData,
      caseReport,
      ruleFormRef,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      getBusinessList,
      getBranchList,
      OnChangeBusiness,
      formValidate,
      activeBusinessId,
      activeBranchId,
      branchList,
      businessList,
    }
  },
})
